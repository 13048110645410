.login {
  width: 100%;

  .login-container {
    width: 320px;
    height: 320px;
    margin: 126px auto 0;
    display: flex;
    align-items: center;
    flex-direction: column;

    > .form-container {
      width: 100%;
      padding: 32px 0 24px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 16px;
    }
    > .button-container {
      width: 100%;
      margin-bottom: 24px;
      > .button {
        height: 42px;
        font-size: 15px;
      }
    }
  }
}
